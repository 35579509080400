<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons.external">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> External Resource</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this resource? All users progress will also be deleted." button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>

						<v-col cols="12">
							<v-select v-model="external_resource.status" :items="resourceStatuses" item-text="label" item-value="value" label="Status"></v-select>
						</v-col>
					</template>
					<template>
						<v-col cols="12">
							<v-text-field v-model="external_resource.title" :rules="validations.title" label="Title"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="external_resource.external_link" :rules="validations.title" label="External Link"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'
import helperMixin from "../../../mixins/helperMixin";

export default {
	name: "ExternalResourcesForm",
	components: {resourceForm},
	props: ['id'],
	mixins: [helperMixin],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		external_resource: sync('external_resources/external_resource'),
	},
	data: () => ({
	    valid: false,
		validations: {
			title: validationRules('Title', ['required', 'minLength:2']),
		},
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('topics/getTopics!', null)
			if (this.isEdit) {
				this.$store.set('external_resources/getExternal_resource!', this.id)
			} else {
				this.external_resource = new models.Resource('ExternalResource')
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.resources.update(this.id, this.external_resource)
						.then(response => {
							this.$toast.add(`External Resource updated successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `ExternalResources`})
						})
						.catch(error => this.$toast.handleResponseErrors(this, error)
						)
				} else {
					this.$api.resources.create(this.external_resource)
						.then(response => {
							this.$toast.add(`External Resource created successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `ExternalResources`})
						})
						.catch(error => {
							this.$toast.handleResponseErrors(this, error)
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.resources.delete(this.id, this.resource)
				.then(response => {
					this.$toast.add(`External Resource deleted successfully`, 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'ExternalResources'})
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>